import React, { useEffect, useState } from 'react';

import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import DocumentView from '../../../../Molecules/DocumentView/DocumentView';
import {
  getBreadCrumb,
  removeHtmlTag,
  formatBytes,
} from '../../../../Utils/Utils';
import { graphql } from 'gatsby';
import Layout from '../../../../Layout';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import useMedias from '../../../../hooks/useMedias';

import '../engagements.scss';
let classNames = require('classnames');

const PrincipauxEnjeux = ({ data }) => {
  const intl = useIntl();
  const { processUrl } = useMedias();

  const { getImage, getDocument } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const documentsArray = data.allDocuments.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  const metaTags =  pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
  metaTags.forEach((meta) => {
    if(meta.attributes.name === 'title'){
        metaTitle = meta.attributes.content;
    }
    if(meta.attributes.name === 'description'){
        metaDesc = meta.attributes.content;
    }
  });
  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');

  let globalText = '';
  let sectionArr = [];
  let leftRightBlocks = [];
  let sectionCount = 0;

  useEffect(() => {
    setInnerNavList(sectionArr);
    setAllText(
      removeHtmlTag(
        `${pageData.title}.${pageData.field_sous_titre !== null &&
          pageData.field_sous_titre !== undefined
          ? pageData.field_sous_titre
          : ''
        }.${globalText}`
      )
    );
  }, []);

  return (
    <Layout>
      <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'principaux_enjeux')}>
        <ScrollPageNav data={innerNavList} />
        <PageBanner
          visuel={getImage(
            imagesArray,
            pageData?.relationships?.field_image?.drupal_internal__mid
          )}
          scroll={true}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                parentPage: {
                  title: breadCrumb?.parent?.name,
                  url: breadCrumb?.parent?.link,
                },
                currentPage: {
                  title: breadCrumb?.current?.name,
                  url: breadCrumb?.current?.link,
                },
                locale: pageData.langcode,
              }}
            />

            <div
              className={classNames({
                title_audio:
                  allText &&
                  pageData.field_version_audio &&
                  allText.length > 30,
              })}
            >
              <TitlePage
                color="color_white"
                title={pageData.title}
                description={pageData.field_sous_titre}
              />
              {allText && pageData.field_version_audio && allText.length > 30 && (
                <AudioComponent
                  text={intl.formatMessage({
                    id: 'detailsActivities.button.listen.label',
                  })}
                  textToRead={allText}
                  color="color_white"
                />
              )}
            </div>
          </div>
        </PageBanner>

        {pageData.relationships?.field_blocs?.map((block, i) => {
          if (block.field_title?.processed) {
            sectionCount++;
            sectionArr.push({
              title: block.field_title?.processed,
              section: 'scroll_to_' + sectionCount,
            });
          }

          switch (block.__typename) {
            case 'block_content__block_texte':
              leftRightBlocks = [];
              globalText += block.field_title?.processed;
              block.relationships?.field_section?.forEach((item) => {
                globalText += `${item.relationships?.field_bloc_texte[0]?.field_title
                    ?.processed != undefined
                    ? item.relationships?.field_bloc_texte[0]?.field_title
                      ?.processed
                    : ''
                  }.${item.relationships?.field_bloc_texte[0]?.field_bloc_text_1?.processed
                  }`;
                leftRightBlocks.push({
                  text: item.relationships?.field_bloc_texte[0]
                    ?.field_bloc_text_1?.processed,
                  title:
                    item.relationships?.field_bloc_texte[0]?.field_title
                      ?.processed,
                  visuel: getImage(
                    imagesArray,
                    item?.relationships?.field_image?.drupal_internal__mid
                  ),
                  arrowColor:
                    item.relationships?.field_bloc_texte[0]?.field_theme_title,
                  imageAlignment: item?.field_alignment,
                  imageNoPadding: item?.field_no_padding,
                  field_backgroud_color: block?.field_backgroud_color,
                });
              });
              return (
                block.relationships?.field_section?.length > 0 && (
                  <section
                    className={classNames(
                      'section_content',
                      'scroll_to_' + sectionCount
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={
                        block.field_backgroud_color === 'ice'
                          ? 'left'
                          : block.field_border_top && 'left'
                      }
                      color={
                        block.field_backgroud_color === 'ice'
                          ? 'catskill_white'
                          : block.field_backgroud_color === 'navy'
                            ? 'dark_bleu' //z-index-top
                            : 'white'
                      }
                      bottomInfinite={block.field_infinite_border}
                      bottom={block.field_border_bottom_display}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          type="arrow"
                          h2color={
                            block.field_backgroud_color === 'navy'
                              ? 'white'
                              : 'bleu'
                          }
                        />

                        {leftRightBlocks.map((secBlock, j) => (
                          <TextVisuelTwoCol
                            key={j}
                            title={secBlock.title}
                            extremeLeft={
                              secBlock?.imageNoPadding &&
                                secBlock?.imageAlignment === 'left'
                                ? true
                                : false
                            }
                            extremeRight={
                              secBlock?.imageNoPadding &&
                                secBlock?.imageAlignment === 'right'
                                ? true
                                : false
                            }
                            orderInverse={
                              secBlock?.imageAlignment === 'right'
                                ? true
                                : false
                            }
                            visuel={secBlock.visuel}
                            //alignLeft={!secBlock?.imageNoPadding && secBlock?.imageAlignment === 'left' ? true : false}
                            alignRight={
                              !secBlock?.imageNoPadding &&
                                secBlock?.field_alignment === 'right'
                                ? true
                                : false
                            }
                            alignTop={true}
                            text={secBlock.text}
                            colorTitle={
                              secBlock.field_backgroud_color === 'navy'
                                ? 'color_white'
                                : undefined
                            }
                            colorDescription={
                              secBlock.field_backgroud_color === 'navy' &&
                              'color_white'
                            }
                          />
                        ))}
                      </div>
                    </ShapeBackground>
                  </section>
                )
              );

            case 'block_content__block_document':
              let docArr = [];
              block.relationships?.field_file?.forEach((file) => {
                docArr.push({
                  name: getDocument(documentsArray, file?.drupal_internal__mid)
                    ?.description,
                  size: `(.pdf ${formatBytes(
                    getDocument(documentsArray, file?.drupal_internal__mid)
                      ?.filesize
                  )})`,
                  link: processUrl(
                    getDocument(documentsArray, file?.drupal_internal__mid)?.uri
                      .url
                  ),
                });
              });
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section_nos_publications',
                    'scroll_to_' + sectionCount
                  )}
                  key={i}
                >
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={block.field_title?.processed}
                      type="arrow"
                    />
                  </div>
                  <div className="wrapper_page">
                    <DocumentView data={docArr} />
                  </div>
                </section>
              );

            default:
              break;
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query enJeuxTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    pageData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_type
          field_padding
          field_sous_titre
          field_version_audio
          path {
            alias
            langcode
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_texte {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom_display
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_section {
                      field_no_padding
                      field_alignment
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                        field_bloc_texte {
                          field_theme_title
                          field_title {
                            processed
                          }
                          field_bloc_text_1 {
                            processed
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
          }
          body {
            processed
          }
        }
      }
    }

    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default PrincipauxEnjeux;
